<template>
  <div class="p-2 mb-2 bg-warning rounded">
    <div class="d-flex flex-column flex-md-row">
      <p class="text-white text-uppercase">{{ title }}</p>
      <p v-if="subtitle" class="text-white">&nbsp;({{ subtitle }})</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
  },
}
</script>

<style>
</style>
